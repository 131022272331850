import React, {useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import { roomsSelector } from './SelectRoomSlice';
import styles from './SelectRoom.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { cacheSessionId } from "../../App";
import WebSocketClient from "../../app/wsClient";
import { activeSessionIdSelector } from "../../AppSlice";
import { Room } from "../../types";

const SelectRoom = () => {
    const rooms = useSelector(roomsSelector);
    const activeSessionId = useSelector(activeSessionIdSelector);

    let ws = useMemo(() => new WebSocketClient(), []);
    const enterPointingSession = (sessionId: string) => {
        ws.send("enterSession", { sessionId });
        cacheSessionId(sessionId);
    }

    useEffect(() => {
        if (rooms.length === 0 && !activeSessionId) {
            ws.send("getAvailableSessions", {});
        }
    }, [rooms.length, activeSessionId, ws]);

    return (
        <div className={styles.selectRoomContainer}>
            { rooms.length > 0 &&
                <>
                    <h2>Enter A Pointing Session:</h2>
                    <div className={styles.sessionSelectContainer}>
                        { rooms.map((room: Room, i: number) => {
                            return (
                                <div
                                    key={i}
                                    className={styles.enterRoom}
                                    onClick={() => enterPointingSession(room.sessionId)}>
                                    <FontAwesomeIcon icon={ faDoorOpen } /> { room.sessionLabel }
                                </div>
                            );
                        })}
                        <div style={{clear: "both"}}></div>
                    </div>
                </>
            }

            { rooms.length === 0 &&
                <>
                    <div>No Available Sessions Found.</div>
                    <div style={{ fontStyle: "italic", marginTop: "10px", color: "#999" }}>Possibly there is no server connection.</div>
                </>
            }
        </div>
    );
}

export default SelectRoom;