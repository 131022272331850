import React, { FormEvent } from 'react';
import styles from './AddParticipantForm.module.css';
import { useDispatch, useSelector } from "react-redux";
import {
    pointingSessionSelector,
    updateParticipantName
} from './PointingSessionSlice';
import {
    clientSelector,
    updateValidationErrMsg,
    updateEditingName
} from "../../AppSlice";
import WebSocketClient from "../../app/wsClient";

const AddParticipantForm = () => {

    let dispatch = useDispatch();
    let ws = new WebSocketClient();
    let pointingSession = useSelector(pointingSessionSelector);
    let client = useSelector(clientSelector);

    const submitParticipantForm = (e: FormEvent) => {
        e.preventDefault();
        let participantPayload = {
            "name": pointingSession.enteringParticipantName,
            "isParticipant": true,
            "sessionId": pointingSession.sessionId
        }
        if (!participantPayload.name) {
            dispatch(updateValidationErrMsg("Name field cannot be empty."));
            return;
        }
        ws.send("updateClient", participantPayload);
        dispatch(updateEditingName(false));
        dispatch(updateValidationErrMsg(""));
    }

    return (
        <div className={styles.addParticipantFormContainer + ((client.isParticipant && !client.editingName) ? ' ' + styles.notAvailable : '')}>
            <form onSubmit={e => submitParticipantForm(e)}>
                <div>
                    <input
                        autoFocus={true}
                        className={styles.enterPointingSessionInput}
                        type="text" name="participantName"
                        placeholder="Enter Your Name"
                        onChange={e => dispatch(updateParticipantName(e.currentTarget.value))}
                        disabled={client.isParticipant && !client.editingName}
                    />
                    <input className={styles.enterPointingSessionButton} type="submit" value="Enter" />
                </div>
            </form>
            <div className={styles.errorMsg}>{ client.validationErrMsg }</div>
        </div>
    );
}

export default AddParticipantForm;