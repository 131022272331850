const ENV_DEVELOPMENT = 'development';

const envConf = env => {
    let prefix = env === ENV_DEVELOPMENT ? 'ws://' : 'wss://';
    let domain = env === ENV_DEVELOPMENT ? 'localhost' : 'agile-story-pointing.com';
    let port   = env === ENV_DEVELOPMENT ? '8080' : '';
    let suffix = env === ENV_DEVELOPMENT ? '' : '/wss/';

    return prefix + domain + (port ? ':' + port : '') + suffix;
}

export default envConf;